.faq{
	@include list-border();
	padding: 25px 30px 25px 0px;
	position: relative;
    @include breakpoint-md{
    	padding: 25px 90px;
    }
	&.opened{
		.icon-svg-arrow-angle-down{
			transform: rotate(180deg);
			transition: transform 0.25s ease-in;
		}
		.faq-question{
			margin-bottom: 5px;
		}
	}
}
.faq-question{
	cursor: pointer;
	.icon-svg-arrow-angle-down{
	    right: 0px;
	    position: absolute;
	    top: 30px;
	    transition: transform 0.175s ease-out;
	    transform-origin: center center;
	    @include breakpoint-sm{
	    	right: 30px;
	    }
	}
	.faq-question-text{
		font-weight: bold;
		@include breakpoint-sm() {
			font-size: 17px;
			line-height: 25px;
		}
		&:before{
			content: 'Q: ';
		}
	}
}
.faq-content{
	display: none;
	p:first-child:before{
		content: 'A: ';
	}
}
.post-type-archive-faq.archive{
	.page-header{
      	@include breakpoint-max($screen-xs-max){
			background-color: transparent;
		}
	}
}
.post-type-archive-faq #posts-list {
	@include breakpoint-max($screen-xs-max) {
    	padding-bottom: 30px;
	}
}