.js-accordion-collapse {

	@include breakpoint-max($screen-sm - 1px) {
		margin-top: 0px !important;
		margin-bottom: 0px !important;
		padding-top: 0px !important;
		padding-bottom: 0px !important;
		&.bg-trapezoid {
			&:before, &:after {
				content: 'normal';
				display: none;
			}
		}
	}
	.accordion-header {
		background-color: $color-black;
		padding: 5px 0px;

		@include breakpoint-sm() {
			display: none !important;
		}
		span {
			display: block;
			padding: 12px 18px;
			font-size: 16px;
			font-weight: bold;
			text-transform: uppercase;
		    background-color: #e170c9;
		    cursor: pointer;
		    @include theme(blue) {
		        background-color: #c00d1e;
		    }
		    @include theme(yellow) {
		        background-color: #e170c9;
		    }
		    @include theme(teal) {
		        background-color: #f1bf9f;
		    }
			.toggle {
				float: right;
				border: none;
				outline: none;
				background: transparent;
				svg {
					transform: rotateZ(180deg);
					transition: transform 333ms ease-in-out;
				}
			}
		}
	}
	.accordion-content {
		display: none;
		background-color: $color-black;
		@include breakpoint-max($screen-sm - 1px) {
			.section-title {
				display: none !important;
			}
		}
		@include breakpoint-sm() {
			display: block !important;
			padding: 0px !important;
			background-color: transparent !important;
		}
	}

	&.accordion-expanded {
		.accordion-header span .toggle svg {
			transform: rotateZ(0deg);
		}
	}

	@include breakpoint-max($screen-sm - 1px) {
		&.accordion-top {
		    @include angle('before', false, 1.1deg);
			content: ' ';
			display: block;
		    margin-top: $line-height-computed;
		    margin-bottom: $line-height-computed;
		    background: black;
	        padding-top: 30px !important;
		    @include breakpoint-sm() {
		        margin-top: $line-height-computed + 10px;
		        margin-bottom: $line-height-computed + 10px;
		        padding: 50px 0;
		    }
		}

		&.accordion-bottom {
		    @include angle('after', true, 1.1deg);
			content: ' ';
			display: block;
		    margin-top: $line-height-computed;
		    margin-bottom: $line-height-computed;
		    background: black;
	        padding-bottom: 40px !important;
		    @include breakpoint-sm() {
		        margin-top: $line-height-computed + 10px;
		        margin-bottom: $line-height-computed + 10px;
		        padding: 50px 0;
		    }
		}
	}
}
.section-heading.accordion-section-heading {
    display: none;
    .show-section-title & {
        @include breakpoint-sm {
            display: block;
        }
    }
}

.accordion-content {
	@include breakpoint-max ($screen-sm) {
		.action-card {
			padding-bottom: 0;
			.card-wrapper {
					padding: 15px;
			}
		}
		.wpb_text_column {
			padding: 15px;
		}
	}
}

@include breakpoint-max($screen-xs-max) {
	.content-section {
		&.has-accordion {
			padding-top: 0px;
			padding-bottom: 0px;
			background-color: $color-black;
			&:nth-of-type(1):not(article) {
				padding-top: 20px;
			}

			&.bg-trapezoid {
				margin-top: 0px;
				margin-bottom: 0px;
				padding: 0px;
				background-color: $color-black;
				&:before, &:after {
					content: normal;
					display: none;
				}
			}
			> .container > .section-heading {
				display: none;
			}
		}
	}
}
