.performances {
	background-color: white;
    margin: 0;
    padding: 40px 20px 20px;
    .performance {
    	@extend .clearfix;

    	border-bottom: 2px solid #dbdbdb;
    	margin-bottom: 30px;
    	padding-bottom: 25px;

    	&:last-child {
    		border-bottom: none;
    	}

    	.info {
    		float: left;
    		width: 100%;
    		.overview {
		    	@extend .clearfix;
		    	h4 {
		    		float: left;
		    		font-size: 33px;
    				font-weight: 600;
    				letter-spacing: 1px;
		    	}
		    	.features {
		    		float: left;
		    		width: 100%;
		    		padding-top: 10px;
		    		ul {
		    			display: inline-block;
		    			list-style: none;
		    			margin: 0;
		    			padding: 0;
		    			width: 75%;
		    			li {
		    				display: inline-block;
		    				position: relative;
		    				background-color: $color-black;
		    				color: $color-white;
		    				font-weight: bold;
		    				padding: 5px 7px;
		    				margin-right: 10px;
		    				margin-bottom: 15px!important;
		    				font-family: 'Druk';
		    				font-size: 16px;
    						text-transform: uppercase;
    						letter-spacing: 1px;
		    				&:before {
		    					content: ' ';
		    					display: block;
		    					position: absolute;
		    					bottom: -6px;
		    					left: 50%;
		    					width: 0;
								height: 0;
								border-style: solid;
								border-width: 6px 6px 0 6px;
								transform: translateX(-6px);
								border-color: $color-black transparent transparent transparent;
		    				}
		    				&.pre {
		    					&:before { 
									border-width: 0 12px 6px 0;
									border-color: transparent $color-black transparent transparent;
		    					}
		    				}
		    				&.post {
		    					&:before {
		    						border-width: 6px 12px 0 0;
									border-color: $color-black transparent transparent transparent;
		    					}
		    				}
		    			}
		    		}
		    		.expand-button {
		    			border: none;
		    			outline: none;
		    			background: none;
						font-weight: bold;	
						transition: transform 333ms;
						margin-left: 5px;
						float: right;
						.fa {
							font-weight: bold;
							font-size: 17px;
						}
		    		}
		    	}
    		}
    		.content {
    			display: none;
    			.featured-content {
    				padding-top: 15px;
    				p {
    					font-size: 15px;
    					padding-bottom: 10px;
    				}
    				.feature-label {
    					font-size: 15px;
    					font-weight: 700;
    				}
    			}  			
    		}
    	}
    	.cta {
    		float: left;
    		text-align: left;
    		width: 190px;
    		padding-top: 15px;
    		.link-button {
    			width: 155px;
    			text-transform: uppercase;
    		}
    	}
    	&.show-content {
    		.expand-button {
    			transform: rotateZ(180deg);
    		}
    	}
    }
    @include breakpoint-sm() {
        margin: 20px 0px;
    	padding: 50px 60px 30px;
    	.performance {
    		border-bottom: 2px solid #dbdbdb;
    		margin-bottom: 30px;
    		padding-bottom: 25px;
    		.info {
    			.overview {
    				h4 {
    					font-size: 35px;
                        line-height: 48px;
                        margin-bottom: 0px;
    				}
    				.features {
    					float: right;
    					width: inherit;
    					padding-top: 8px;
    					ul {
    						width: inherit;
    						li {
    							margin-left: 10px;
    							margin-right: 0;
    							margin-bottom: 0!important;
    						}
    					}
    					.expand-button {
    						float: none;
    					}
    				}
    				.content {
    					.featured-content {
    						p {
    							font-size: 17px;
    						}
    						.feature-label {
    							font-family: 17px;
    						}
    					}
    				}
    			}
    		}
    	}
    }

    @include breakpoint-md() {
    	padding: 55px 90px 35px;
    	.performance {
    		margin-bottom: 30px;
    		padding-bottom: 25px;
    		.info {
    			float: left;
    			width: calc(100% - 190px);
    		}
    		.cta {
    			float: right;
    			text-align: right;
    			padding-top: 0px;
    		}
    	}
    }
}

#show-all-performances {
    margin: 30px 0px 10px;
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    color: $color-white;
    padding: 12px 18px;
    border: 1px solid white;
}
