// Search form
.search-form {
    @extend .form-inline;
}
.search-form label {
    font-weight: normal;
    @extend .form-group;
}
.search-form .search-field {
    @extend .form-control;
}
.search-form .search-submit {
    @extend .btn;
    @extend .btn-default;
}

// Gravity Forms
.gform_wrapper {
	li {
		margin-bottom: 25px!important;
	}
	.gfield_checkbox, .gfield_radio {
		li {
			margin-bottom: 15px!important;
		}
	}
	.ginput_complex {
		span {
			input { margin-bottom: 5px; }
			label { 
				padding-bottom: 15px; 
				font-size: 15px;
				font-weight: 100;
			}
		}
	}
	input[type="text"] + label {
		font-size: 15px;
		font-weight: 100;
	}
	.gform_fields {
		.gfield {
			&[class*="col-"] {
				clear: none;
				padding-left: 0px;
				padding-right: 0px;
			}
		}
	}
	.hidden_label .gfield_label {
	    display: block;
	    visibility: hidden;
	    margin: 0px;
	    height: 0px;
	    line-height: 0;
	}
	.gfield_description {
		padding-bottom: 15px;
	}
	.gsection_title {
		font-size: 16px;
	}
	.ginput_container {	
		input, textarea, select {
			height: auto;
			padding: 15px;
			color: $color-black;
			border-width: 1px;
			border-color: #7f7f7f;

			&::placeholder {
				color: $color-black;
			}

			@include breakpoint-sm() {
				border-width: 2px;
				border-color: $color-black;
			}
		}	
		&.ginput_container_text {
		}
		&.ginput_container_select {
			.select_wrapper {
				color: $color-black;
				position: relative;
				margin-bottom: 5px;
				&:after {
					content: '\f107';
					display: block;
					font-family: 'FontAwesome';
					font-size: 25px;
					position: absolute;
					top: 50%;
					right: 15px;
					transform: translateY(-50%);
					transition: transform 333ms ease-in-out;
				}
			}
			select {
				padding: 15px;
				-webkit-appearance: none;
			}
		}
		&.ginput_container_checkbox {
			input[type="checkbox"] {
				border: 1px solid #7f7f7f;
			    -webkit-appearance: none;
			    &:checked {
			    	background-image: url('../images/svg-sprite/checkmark.svg');
			    	background-size: contain;
			    	background-repeat: no-repeat;
			    	background-position: center center;
			    }
				@include breakpoint-sm() {
					border-width: 2px;
					border-color: $color-black;
				}
			}
			*[class^="gchoice"] {
			    display: flex;
			    align-items: center;
			}
		}
		&.ginput_container_radio {
			input[type="radio"] {
				border: 1px solid #7f7f7f;
				border-radius: 50%;
			    -webkit-appearance: none;
			    &:checked {
			    	background-image: url('../images/svg-sprite/checkmark.svg');
			    	background-size: contain;
			    	background-repeat: no-repeat;
			    	background-position: center center;
			    }
				@include breakpoint-sm() {
					border-width: 2px;
					border-color: $color-black;
				}
			}
			*[class^="gchoice"] {
			    display: flex;
			    align-items: center;
			}
		}
	}
	.gform_footer {
		text-align: center;
		padding: 30px 0 20px;
		input[type="submit"] {
			padding: 15px 18px;
			font-size: 16px;
			width: 100%;
		    font-weight: bold;
		    display: inline-block;
		    text-align: center;
			border: 1px solid $color-white;
			background-color: $color-black;
			color: $color-white;
			@include breakpoint-sm() {
				width: 300px;
			}
		}
	}
}