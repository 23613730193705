body.post-type-archive {
	&-news .page-header .background {
		background-image: url('../images/page-header-news.jpg');
	}
	&-article .page-header .background {
		background-image: url('../images/page-header-indefinite-articles.jpg');
	}
	.action-card .card-wrapper.no-image .content {
		@include breakpoint-sm() {
			padding-bottom: 0px;
		}
	}
}
.post-type-archive-news #posts-list {
	padding-top: 40px;
}
.post-type-archive-article #posts-list {
	padding-top: 40px;
}

#posts-list .navigation + .navigation {
	padding-top: 40px;
}