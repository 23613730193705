.staff-list {
    position: relative;
    &:before {
        position: absolute;
        display: block;
        left: 15px;
        right: 15px;
        top: 0;
        height: 1px;
        background: #b2b2b2;
        @include breakpoint-max($screen-xs-max) {
            display: none;
        }
    }
    &__title {
        font-size: 18px;
        line-height: 30px;
        letter-spacing: 1px;
        padding-bottom: 10px;
        @include breakpoint-max($screen-xs-max) {
            display: none;
        }
    }
}