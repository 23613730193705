.filters{
  position: relative;
  padding-bottom: 10px;
  padding-top: 5px;
  text-align: center;
  @include breakpoint-sm() {
    padding-top: 45px;
    padding-bottom: 40px;
    text-align: right;
  }
  @include breakpoint-md() {
    padding-bottom: 75px;
  }
}
.events-filter{
  background-color: $color-pink;

  @include angle('after', true, 1.1deg);
  @include breakpoint-sm() {
    background-color: transparent;
  }
}
select::-ms-expand {
    display: none;
}
.filters-dropdown{
  appearance:none;
  background-color: #fff;
  border: none;
  border-radius: 0;
  display: inline-block;
  font-weight: bold;
  height: 50px;
  outline: none;
  padding: 0 16px;
  text-transform: uppercase;
  width: 100%;
  @include breakpoint-sm() {
    background-color: $color-yellow;
    height: 47px;
    padding: 0 12px;
  }
  @include breakpoint-md() {
    height: 47px;
    padding: 0 18px;
  }
}
.clear-filters{
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  line-height: 2;
  letter-spacing: 1px;
  margin-top: 10px;
  text-decoration: underline;
  text-transform: uppercase;
  @include breakpoint-sm() {
    font-size: 13px;
  }
  @include breakpoint-md() {
    font-size: 16px;
  }
}
.dropdown{
  display: inline-block;
  margin-bottom: 5px;
  position: relative;
  width: 100%;
  font-size: 16px;

  @include breakpoint-sm() {
    margin-bottom: 0;
    margin-left: 15px;
    width: 150px;
    font-size: 13px;
  }
  @include breakpoint-md() {
    margin-bottom: 0;
    margin-left: 20px;
    width: 190px;
    font-size: 16px;
  }
  @include breakpoint-lg() {
    margin-bottom: 0;
    width: 200px;
  }
}
.icon-svg-arrow-dropdown{
  background-color: #fff;
  right: 18px;
  pointer-events: none;
  @include absolute-centered(vertical);
  @include breakpoint-sm() {
    background-color: $color-yellow;
  }
}
.mobile-day{
  margin-bottom: 35px;
}