body, .wpb_text_column, .text_block {
	h1 {

	}
	h2 {
		font-size: 24px;
        line-height: 25px;
        padding: 20px 0px 25px;
        margin: 0;
        @include breakpoint-xs() {
        	font-size: 27px;
        	line-height: 30px;
        	padding: 20px 0px 30px;
        }
	}
	h3 {

	}
	h4 {
		font-size: 18px;
        line-height: 22px;
	}
	h5 {

	}
	h6 {

	}
	p {
		font-size: 15px;
        line-height: 22px;
        padding-bottom: 10px;
        strong, b {
        	letter-spacing: 1px;
        }
        @include breakpoint-sm() {
        	font-size: 17px;
        	line-height: 26px;
        	padding-bottom: 15px;
        }
	}
	ul {
		li {

		}
	}
	ol {
		li {

		}
	}
	a {
		font-weight: bold;
	}
}
