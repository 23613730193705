$animations : (
  bounce : (
      (
        vals : (
          transform: translate3d(20px, 0, 0),
          opacity: 0
        )      
      ),
      (
        vals : (
          transform: translate3d(0px, 0, 0),
          opacity: 1
        )
      )
  ),
  fadeInSpin : (
    (
      vals : (
        transform: rotateY(50deg),
        opacity: 0
      )      
    ),
    (
      vals : (
        opacity : 1,
        transform : rotateY(0deg)
      )
    )
  ),
  fadeInLeft : (
    (
      vals : (
        opacity : 0,
        transform : translate3d(-12px, 0, 0)
      )
    ), 
    (
      vals : (
        opacity : 1,
        transform : none
      )
    )
  ),
  fadeInRight : (
    (
      vals : (
        opacity : 0,
        transform : translate3d(12px, 0, 0)
      )
    ), 
    (
      vals : (
        opacity : 1,
        transform : none
      )
    )
  ),
  fadeInLeftSkir : (
    (
      vals : (
        opacity : 0,
        transform : translateX(-50px) rotate(5deg)
      )
    ), 
    (
      vals : (
        opacity : 1,
        transform : none
      )
    )
  ),
  fadeInRightSkir : (
    (
      vals : (
        opacity : 0,
        transform : translateX(50px) rotate(5deg)
      )
    ), 
    (
      vals : (
        opacity : 1,
        transform : none
      )
    )
  ),
  fadeInRightFull : (
    (
      vals : (
        opacity : 0,
        transform : translate3d(100%, 0, 0)
      )
    ), 
    (
      vals : (
        opacity : 1,
        transform : none
      )
    )
  ),
  fadeInRightHalf : (
    (
      vals : (
        opacity : 0,
        transform : translate3d(50%, 0, 0)
      )
    ), 
    (
      vals : (
        opacity : 1,
        transform : none
      )
    )
  ),
  fadeInDown : (
    (
      vals : (
        opacity : 0,
        transform : translate3d(0, -12px, 0)
      )
    ), 
    (
      vals : (
        opacity : 1,
        transform : none
      )
    )
  ),
  fadeInUp : (
    (
      vals : (
        opacity : 0,
        transform : translate3d(0, 50px, 0)
      )
    ), 
    (
      vals : (
        opacity : 1,
        transform : none
      )
    )
  ),
  fadeOutLeft : (
    (
      vals : (
        opacity : 1,
        transform : translate3d(-12px, 0, 0)
      )
    ), 
    (
      vals : (
        opacity : 0,
        transform : none
      )
    )
  ),
  fadeOutRight : (
    (
      vals : (
        opacity : 1,
        transform : translate3d(12px, 0, 0)
      )
    ), 
    (
      vals : (
        opacity : 0,
        transform : none
      )
    )
  ),
  fadeOutDown : (
    (
      vals : (
        opacity : 1,
        transform : translate3d(0, -12px, 0)
      )
    ), 
    (
      vals : (
        opacity : 0,
        transform : none
      )
    )
  ),
  fadeOutUp : (
    (
      vals : (
        opacity : 1,
        transform : translate3d(0, 12px, 0)
      )
    ), 
    (
      vals : (
        opacity : 0,
        transform : none
      )
    )
  ),
  fadeOutScale : (
    (
      vals : (
        opacity : 1,
        transform : scale(1),
        filter : none
      )
    ), 
    (
      vals : (
        opacity : 0,
        transform : scale(0.9875),
        filter : blur(4px)
      )
    )
  ),
  fadeInScale : (
    (
      vals : (
        opacity : 0,
        transform : scale(0.9875),
        filter : blur(4px)
      )
    ),
    (
      vals : (
        opacity : 1,
        transform : scale(1),
        filter : none
      )
    )
  ),
  fadeOut : (
    (
      vals : (
        opacity : 1
      )
    ), 
    (
      vals : (
        opacity : 0
      )
    )
  ),
  fadeIn : (
    (
      vals : (
        opacity : 0
      )
    ), 
    (
      vals : (
        opacity : 1
      )
    )
  )
);