/*
        This font software is the property of Commercial Type.

        You may not modify the font software, use it on another website, or install it on a computer.

        License information is available at http://commercialtype.com/eula
        For more information please visit Commercial Type at http://commercialtype.com or email us at info[at]commercialtype.com

        Copyright (C) 2017 Schwartzco Inc.
        License: 1706-BDPKXD
*/

@font-face {
    font-family: 'Druk';
    src: url('../fonts/DrukTextWide-Medium-Web.eot');
    src: url('../fonts/DrukTextWide-Medium-Web.eot?#iefix') format('embedded-opentype'),
    url('../fonts/DrukTextWide-Medium-Web.woff2') format('woff2'),
    url('../fonts/DrukTextWide-Medium-Web.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: 'Druk';
    src: url('../fonts/DrukWide-Medium-Web.eot');
    src: url('../fonts/DrukWide-Medium-Web.eot?#iefix') format('embedded-opentype'),
    url('../fonts/DrukWide-Medium-Web.woff2') format('woff2'),
    url('../fonts/DrukWide-Medium-Web.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: 'Druk';
    src: url('../fonts/Druk-Bold-Web.eot');
    src: url('../fonts/Druk-Bold-Web.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Druk-Bold-Web.woff2') format('woff2'),
    url('../fonts/Druk-Bold-Web.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
}
