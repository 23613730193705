.single-event {
	article {
		background-color: $color-teal;
    	padding: 0;
    	@include theme(blue) {
        	background-color: $color-blue;
    	}
    	@include theme(yellow) {
        	background-color: $color-yellow;
    	}
    	@include theme(teal) {
        	background-color: $color-teal;
    	}
    	@include breakpoint-sm() {
        	background-color: transparent;
        	padding-top: 30px;
        	padding-bottom: 40px;
    	}
		.article-content {
			background-color: $color-white;
			padding: 15px;
	    	@include breakpoint-sm() {
				background-color: transparent;
				padding: 0px;
	    	}
		}
	}

	@include breakpoint-max($screen-xs-max) {
		&.theme-blue {
			article { background-color: $color-blue; }    	
		}
		&.theme-yellow {
			article { background-color: $color-yellow; }    	
		}
		&.theme-teal {
			article { background-color: $color-teal; }    	
		}
	}

	#tickets {
		&.bg-trapezoid {
			margin: 0 0 10px;
        	@include breakpoint-sm() {
            	margin: 30px 0;
        	}
    	}
		.ticket-preamble {
			.textbox {
				background-color: white;
		    	padding: 30px;
		    	text-align: left;
		        margin-bottom: 5px!important;
		    	h3 {
		    		font-size: 15px;
		    	}
		    	p {
		    		font-size: 17px;
		    	}
		    	a {
		    		font-size: 17px;
		    		color: black;
		    		font-weight: 600;
		    	}
		        @include breakpoint-sm() {
			    	text-align: center;
		            margin-top: 0px!important;
		            margin-bottom: 0px!important;
		        }
			}
		}
	}
	#media {
		padding: 45px 0 15px;
		@include breakpoint-sm() {
			padding: 60px 0 30px;
		}
	}
	#recommended-shows {
		.recommended-intro {
			padding: 0 0 25px;
			p {
				font-size: 15px;
			}
			@include breakpoint-xs() {
				p {
					font-size: 17px;
				}
			}
			@include breakpoint-sm() {
				padding: 0 0 30px;
				margin-top: -15px;
			}
		}
	}
	#disqus_thread {
		padding: 15px;
		background-color: $color-white;
		@include breakpoint-sm() {
			padding: 0px;
		}
	}
}

.show-more-wrapper {
	#show-more-link, #show-less-link {
		font-size: 17px;
		font-weight: bold;
		color: $color-black;
		display: inline-block;
		text-decoration: none;
		padding-top: 10px;
		&:after {
			content: '\f107';
		    font-family: FontAwesome;
		    float: right;
		    margin-left: 10px;
		    transform: rotateZ(0deg);
		    transition: transform 333ms ease-in-out;
		}
		&:hover {
			text-decoration: underline;
		}
	}
	#show-less-link {
		display: none;
		&:after {
	    	transform: rotateZ(180deg);
	    }
	}
	.show-more-content {
		display: none;
	}
	&.show-more-opened {
		#show-more-link {
		    display: none;
		}
		#show-less-link {
		    display: inline-block;
		}
	}	
}
