html {
    height: 100%;
}

body {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.wrap {
    flex: 1 0 auto;
}

.container {
	width: 100%;
    max-width: $container-max-width;
}

img {
    @extend .img-responsive;
}
* > :first-child {
    margin-top: 0 !important;
}

* > :last-child {
    margin-bottom: 0 !important;
}

body.fill-gap {
    .wrap {
        flex: 0 0 auto;
    }
    .gap {
        height: 100%;
        flex: 1 0 auto;
        background: black;
        margin-top: -10px;
        @include breakpoint-sm() {
            margin-top: -50px;
        }
    }
    .footer {
        flex: 0 0 auto;
    }
}

body.disable-tel {
    a[href^="tel:"] {
        pointer-events: none;
    }
}