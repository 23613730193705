.page-header {
    position: relative;
    @include breakpoint-max($screen-xs-max) {
        background-color: $color-teal;
        @include theme(blue) {
            background-color: $color-blue;
        }
        @include theme(yellow) {
            background-color: $color-yellow;
        }
        @include theme(teal) {
            background-color: $color-teal;
        }
        &.has-banner {
            background-color: $color-teal;
            @include theme(blue) {
                background-color: $color-blue;
            }
            @include theme(yellow) {
                background-color: $color-yellow;
            }
            @include theme(teal) {
                background-color: $color-teal;
            }
        }
        background-image: none !important;
        @include angle('after', true, 1.1deg);

        &.has-banner .background {
            background: $brand-primary top center no-repeat;
            background-size: cover;
            min-height: 300px;
            max-height: 350px;
            &:before {
                content: ' ';
                display: block;
                padding-bottom: 460/640 * 100%;
            }
        }
        .foreground {
            padding: 25px 15px 20px;
            .tape {
                h1 {
                    font-size: 23px;
                }
                h2 {
                    font-size: 14px;
                    line-height: normal;
                    margin: 0;
                    padding-top: 0;
                    padding-bottom: 0;
                }
            }
        }
        .page-header__nav {
            display: none;
        }
    }
    @include breakpoint-custom($screen-sm) {
        .background {
            min-height: 185px;   
            background-color: $color-peach;
            @include theme(blue) {
                background-color: $color-red;
            }
            @include theme(yellow) {
                background-color: $color-pink;
            }
            @include theme(teal) {
                background-color: $color-peach;
            }
            &.has-banner {
                background-color: $color-peach;
                @include theme(blue) {
                    background-color: $color-red;
                }
                @include theme(yellow) {
                    background-color: $color-pink;
                }
                @include theme(teal) {
                    background-color: $color-peach;
                }
            }
        }
        &.has-banner .background {
            background: $brand-primary top center no-repeat;
            background-size: cover;
            min-height: 400px;
            max-height: 450px;
            &:before {
                content: ' ';
                display: block;
                padding-bottom: 9/32 * 100%;
            }
        }
        .foreground {
            position: relative;
            display: block;
            .tape {
                position: relative;
                display: inline-block;
                top: auto;
                left: auto;
                margin: 0px 0px 0px 2.0em;
                width: 55%;
                min-width: 400px;
                max-width: 500px;
                z-index: 100;
                transform-origin: left center;
                transform: rotate(-4.8deg) translateY(-30%);
                h1 {
                    font-size: 42px;
                    padding-left: 30px;
                    .word {
                        position: relative;
                        display: inline-block;
                        &:before {
                            content: ' ';
                            position: absolute;
                            top: -10px;
                            left: -20px;
                            right: -20px;
                            bottom: -10px;
                            background: $color-teal;
                            z-index: -1;
                            @include theme(blue) {
                                background: $color-blue;
                            }
                            @include theme(yellow) {
                                background: $color-yellow;
                            }
                            @include theme(teal) {
                                background: $color-teal;
                            }
                        }
                        &:first-child {
                            margin-left: -30px;
                            &:before {
                                left: -40px;
                            }
                        }
                    }
                }
                h2 {
                    font-size: 14px;
                    line-height: normal;
                    margin-top: -10px;
                    padding-left: 20%;
                    padding-top: 0;
                    padding-bottom: 0;
                    .word {
                        position: relative;
                        display: inline-block;
                        &:before {
                            content: ' ';
                            position: absolute;
                            top: -10px;
                            left: -20px;
                            right: -20px;
                            bottom: -10px;
                            background: $color-teal;
                            z-index: -1;
                            @include theme(blue) {
                                background: $color-blue;
                            }
                            @include theme(yellow) {
                                background: $color-yellow;
                            }
                            @include theme(teal) {
                                background: $color-teal;
                            }
                        }
                        &:first-child {
                            margin-left: -30px;
                        }
                    }
                }
            }
            .page-header__nav {

                display: block;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                text-align: right;
                background-color: $color-peach;
                min-height: 30px;
                @include theme(blue) {
                    background-color: $color-red;
                }
                @include theme(yellow) {
                    background-color: $color-pink;
                }
                @include theme(teal) {
                    background-color: $color-peach;
                }
                ul {
                    float: right;
                    list-style: none;
                    margin: 0px 15px 0px 0px;
                    padding: 0;
                    li {
                        display: inline-block;
                        padding: 0px 12px;
                        a {
                            font-family: 'Druk';
                            font-size: 18px;
                            line-height: 60px;
                            font-weight: 700;
                            color: white;
                            letter-spacing: 2px;
                            text-transform: uppercase;
                        }
                    }
                }
                &.no-room {
                    ul {
                        display: none;
                    }
                }
            }
        }
        &.has-banner .foreground {
            .tape {
                top: 100%;
            }
        }
    }
    @include breakpoint-custom($screen-md) {
        .foreground {
            .tape {
                max-width: 600px;
                h1 {
                    font-size: 52px;
                }
            }
        }
    }
    @include breakpoint-custom($screen-lg) {
        .foreground {
            .tape {
                max-width: 700px;
                h1 {
                    font-size: 59px;
                }
            }
        }
    }
}

.post-type-archive-event .page-header {
    .page-header__nav {display: none;}
    min-height: 65px;
    background-color: $color-pink;
    @include breakpoint-custom($screen-sm) {
        min-height: 155px;
        background-color: transparent;
    }
    &:after {
        content: normal;
        display: none;
    }
}