.search-header{
	@include list-border();
	visibility: hidden;
	height: 80px;
    @include breakpoint-sm{
		height: auto;
    	padding: 70px 0 40px;
		visibility: visible;
    }
    h2 {
    	font-size: 37px;
    	padding: 0;
    }
}
.search-result{
	@include list-border();
	padding: 20px 0;
	h5 {
		font-size: 15px;
		margin-bottom: 5px;
		@include breakpoint-sm() {
			font-size: 16px;
			margin-bottom: 10px;
		}
	}
}
.search-result-title{
	margin-top: 0;
	a{
		color: #000;
		font-size: 18px;
		font-weight: 500;
		@include breakpoint-sm() {
			font-size: 27px;
		}
	}
}