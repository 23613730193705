.fc {
    direction: ltr;
    text-align: left;

    table {
        border-collapse: collapse;
        border-spacing: 0;
    }

    .btn {
        line-height: 1.2em;
    }
}

html .fc {
    font-size: 1em;
}

.fc {
    table {
        font-size: 1em;
    }

    td, th {
        padding: 1px;
        vertical-align: top;
    }
}

//Header

.fc-header td {
    white-space: nowrap;
}

.fc-header-left {
    width: 25%;
    text-align: left;
}

.fc-header-center {
    text-align: center;
}

.fc-header-right {
    width: 25%;
    text-align: right;
}

.fc-header-title {
    display: inline-block;
    vertical-align: top;

    h2 {
        margin-top: 0;
        white-space: nowrap;
    }
}

.fc .fc-header-space {
    padding-left: 10px;
}

.fc-header {
    .fc-button {
        margin-bottom: 1em;
        vertical-align: top;
        margin-right: -1px;
    }

    .fc-corner-right {
        margin-right: 1px;
    }

    .ui-corner-right {
        margin-right: 0;
    }

    .fc-state-hover, .ui-state-hover {
        z-index: 2;
    }

    .fc-state-down {
        z-index: 3;
    }

    .fc-state-active, .ui-state-active, .ui-state-down {
        z-index: 4;
        background: $brand-primary;
        background-image: none;
        outline: 0;
        box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
    }
}

//Content

.fc-content {
    clear: both;
}

.fc-view {
    width: 100%;
    overflow: hidden;
}

//Cell Styles

.fc .ui-widget-header {
    border-color: #dddddd;
    padding: 4px 0;
}

thead th.fc-first {
    border-top-left-radius: 5px;
}

thead th.fc-last {
    border-top-right-radius: 5px;
}

.fc-state-highlight {
    background: #ffffcc;
}

.ui-state-highlight.fc-today {
    background: #f4f4f4;
    margin: 2px !important;
    border: 0;
    border-left: 1px solid #dddddd;
    border-radius: 0;
}

.fc-cell-overlay {
    background: #99ccff;
    opacity: 0.2;
    filter: alpha(opacity=20);
}

//Buttons

.fc-button {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.fc-state-default {
    border-style: solid;
    border-width: 1px 0;
}

.fc-button-inner {
    position: relative;
    float: left;
    overflow: hidden;
}

.fc-state-default .fc-button-inner {
    border-style: solid;
    border-width: 0 1px;
}

.fc-button-content {
    position: relative;
    float: left;
    height: 1.9em;
    line-height: 1.9em;
    padding: 0 0.6em;
    white-space: nowrap;

    .fc-icon-wrap {
        position: relative;
        float: left;
        top: 50%;
    }

    .ui-icon {
        position: relative;
        float: left;
        margin-top: -50%;
        *margin-top: 0;
        *top: -50%;
    }
}

.fc-state-default {
    .fc-button-effect {
        position: absolute;
        top: 50%;
        left: 0;

        span {
            position: absolute;
            top: -100px;
            left: 0;
            width: 500px;
            height: 100px;
            border-width: 100px 0 0 1px;
            border-style: solid;
            border-color: white;
            background: #444444;
            opacity: 0.09;
            filter: alpha(opacity=9);
        }
    }

    border-style: solid;
    border-color: #cccccc #bbbbbb #aaaaaa;
    background: #f3f3f3;
    color: black;

    .fc-button-inner {
        border-style: solid;
        border-color: #cccccc #bbbbbb #aaaaaa;
        background: #f3f3f3;
        color: black;
    }
}

.fc-state-hover {
    border-color: #999999;

    .fc-button-inner {
        border-color: #999999;
    }
}

.fc-state-down {
    border-color: #555555;
    background: #777777;

    .fc-button-inner {
        border-color: #555555;
        background: #777777;
    }
}

.fc-state-active {
    border-color: #555555;
    background: #777777;
    color: white;

    .fc-button-inner {
        border-color: #555555;
        background: #777777;
        color: white;
    }
}

.fc-state-disabled {
    color: #999999;
    border-color: #dddddd;

    .fc-button-inner {
        color: #999999;
        border-color: #dddddd;
    }

    cursor: default;

    .fc-button-effect {
        display: none;
    }
}

//Global Event Styles

.fc-event {
    border-style: solid;
    border-width: 0;
    font-size: 0.85em;
    cursor: default;
}

a.fc-event, .fc-event-draggable {
    cursor: pointer;
}

a.fc-event {
    text-decoration: none;
}

.fc-rtl .fc-event {
    text-align: right;
}

.fc-event-skin {
    border: 0;
    background-color: #3366cc;
    color: white;
}

.fc-event-inner {
    position: relative;
    width: 100%;
    height: 100%;
    border-style: solid;
    border-width: 0;
    overflow: hidden;
}

.fc-event-time, .fc-event-title {
    padding: 0 1px;
}

.fc .ui-resizable-handle {
    display: block;
    position: absolute;
    z-index: 99999;
    overflow: hidden;
}

//Horizontal Events

.fc-event-hori {
    border-width: 1px 0;
    margin-bottom: 1px;

    .ui-resizable-e {
        top: 0 !important;
    }

    .ui-resizable-w {
        top: 0 !important;
        left: (-3px) !important;
        width: 7px !important;
        height: 100% !important;
        cursor: w-resize;
    }

    .ui-resizable-handle {
        _padding-bottom: 14px;
    }
}

//Reusable Separate-border Table

table.fc-border-separate {
    border-collapse: separate;
}

.fc-border-separate {
    th, td {
        border-width: 1px 0 0 1px;
    }

    th.fc-last, td.fc-last {
        border-right-width: 1px;
    }

    tr.fc-last {
        th, td {
            border-bottom-width: 1px;
        }
    }

    tbody tr.fc-first {
        td, th {
            border-top-width: 0;
        }
    }
}

//Month View, Basic Week View, Basic Day View

.fc-grid {
    th {
        text-align: center;
    }

    .fc-day-number {
        float: right;
        padding: 0 2px;
    }

    .fc-other-month .fc-day-number {
        opacity: 0.3;
        filter: alpha(opacity=30);
    }
}

.fc-rtl .fc-grid {
    .fc-day-number {
        float: left;
    }

    .fc-event-time {
        float: right;
    }
}

//Agenda Week View, Agenda Day View

.fc-agenda table {
    border-collapse: separate;
}

.fc-agenda-days th {
    text-align: center;
}

.fc-agenda {
    .fc-agenda-axis {
        width: 50px;
        padding: 0 4px;
        vertical-align: middle;
        text-align: right;
        white-space: nowrap;
        font-weight: normal;
    }

    .fc-day-content {
        padding: 2px 2px 1px;
    }
}

.fc-agenda-days {
    .fc-agenda-axis {
        border-right-width: 1px;
    }

    .fc-col0 {
        border-left-width: 0;
    }
}

.fc-agenda-allday {
    th {
        border-width: 0 1px;
    }

    .fc-day-content {
        min-height: 34px;
    }
}

.fc-agenda-divider-inner {
    height: 2px;
    overflow: hidden;
}

.fc-widget-header .fc-agenda-divider-inner {
    background: #eeeeee;
}

//slot rows

.fc-agenda-slots {
    th {
        border-width: 1px 1px 0;
    }

    td {
        border-width: 1px 0 0;
        background: none;

        div {
            height: 20px;
        }
    }

    tr {
        &.fc-slot0 {
            th, td {
                border-top-width: 0;
            }
        }

        &.fc-minor {
            th, td {
                border-top-style: dotted;
            }

            th.ui-widget-header {
                *border-top-style: solid;
            }
        }
    }
}

//Vertical Events
//--------------------------------------

.fc-event-vert {
    border-width: 0 1px;

    .fc-event-head, .fc-event-content {
        position: relative;
        z-index: 2;
        width: 100%;
        overflow: hidden;
    }

    .fc-event-time {
        white-space: nowrap;
        font-size: 10px;
    }
}

.fc .ui-draggable-dragging .fc-event-bg, .fc-select-helper .fc-event-bg {
    display: none;
}

.fc-event-vert .ui-resizable-s {
    bottom: 0 !important;
}
