.action-card {
	@extend .anim;
	opacity: 0;
    padding-bottom: 30px;
    @include breakpoint-sm() {
    	padding-bottom: 40px;
    }
    &.visible{
		@extend .fadeInUp;
		@include extendAnimation($animations,fadeInUp,end);
    }
	.card-wrapper {
		@extend .clearfix;
		background-color: white;
	}
	.image-link-wrapper {
		display: block;
	}
	.image {
		width: 100%;
		height: 100%;
		position: relative;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: top center;
		transition: background-image 0.125s ease-in;
		img {
			width: 0;
			height: 0;
		}
		&:after {
			content: ' ';
			display: block;
			padding-bottom: 70.69%;
		}
	}
	.content {
	    display: flex;
	    flex-direction: column;
	    justify-content: center;
		padding: 20px 0 0;
		.link-button {
			padding: 14px 18px;
			min-width: 45%;
			&.white {
				margin-right: 10px;
			}
		}
		h3 {
			font-size: 22px;
			font-weight: 400;
			line-height: 23px;
			margin: 0;
			padding-bottom: 15px;
			color: black;
			a {
				color: black;
				font-weight: inherit;
			}
		}
		p {
			padding: 0px 0 15px;
		}
	}
	@include breakpoint-sm() {
		.image-link-wrapper {
			width: 49%;
			float: left;
		}
		.image {
			width: 100%;
		}
		.content {
			width: 51%;
			float: right;
			padding: 30px;
			h3 {
				font-size: 27px;
				line-height: 28px;
				padding-bottom: 15px;
			}
			p {
				padding: 0px 0 20px;
			}
			.link-button {
				padding: 12px 18px;
				min-width: 47%;
				&.white {
					margin-right: 15px;
				}
			}
		}
		.card-wrapper.no-image .content {
			width: 100%;
			.link-button {
				min-width: 33%;
			}
		}
	}
	@include breakpoint-md() {
		.image {
			&:after {
				padding-bottom: 58.43%;
			}
		}
	}
}
.action-card--style-bold {
	&:nth-child(even){
		transform-origin: top left;
	    &.visible{
			@extend .fadeInLeftSkir;
			@include extendAnimation($animations,fadeInLeftSkir,end);
	    }   	
    }
	&:nth-child(odd){
		transform-origin: top right;
	    &.visible{
			@extend .fadeInRightSkir;
			@include extendAnimation($animations,fadeInRightSkir,end);
	    } 
    }
	.content {
		h3 {
			font-size: 56px;
			font-weight: 700;
			line-height: 52px;
			margin: 0;
			padding-bottom: 15px;
			@include breakpoint-sm() {
				font-size: 50px;
				line-height: 45px;
				padding-bottom: 20px;
			}
			@include breakpoint-md() {
				font-size: 65px;
				line-height: 62px;
			}
		}
		h6 {
			font-size: 14px;
			font-weight: 400;
			letter-spacing: 1px;
			margin: 0;
			padding-bottom: 15px;
			@include breakpoint-md() {
				font-size: 15px;
			}
			a {
				font-weight: inherit;
				color: inherit;
			}
		}
	}
}
.action-card--tweet {
	.content {
		h3 {
			i.fa {
				float: left;
				font-size: 50px;
			}
			.text {
				width: calc(100% - 60px);
				margin-left: 60px;
				display: block;

				strong {
					display: block;
					overflow-wrap: break-word;
					word-wrap: break-word;
					hyphens: auto;
					font-size: 30px;
					line-height: 30px;
					font-weight: 700;
					padding-top: 3px;
					letter-spacing: 1px;
				}
				small {
					display: block;
					font-size: 13px;
					line-height: 16px;
					font-weight: 400;
					color: black;
					overflow-wrap: break-word;
					word-wrap: break-word;
				}
			}
		}
		a {
			color: black;
		}
	}
}
.col-sm-4, .col-md-4, .col-lg-4,
.col-sm-6, .col-md-6, .col-lg-6 {
	.action-card {
		padding-bottom: 15px;
		padding-top: 15px;
		@include breakpoint-sm() {
			padding-bottom: 30px;
			padding-top: 0px;
		}

		.content {
			padding: 20px 0;
			width: inherit;
    		float: none;
		    justify-content: space-between;
			h3 {
				font-size: 22px;
				font-weight: 400;
				line-height: 1.1;
				margin: 0;
				padding-bottom: 15px;
			}
			p {
				padding: 0 0 15px;
			}
			@include breakpoint-sm() {
				h3 {
					font-size: 17px;
					padding-bottom: 20px;
				}
				p {
					padding: 0 0 20px;
				}
			}
			@include breakpoint-md() {
				padding: 30px 0;
				h3 {
					font-size: 18px;
				}
			}
		}
		.image {
			display: none;
		}
		@include breakpoint-sm() {
			.content .buttons {
				margin-top: auto;
			}
			.image-link-wrapper {
				display: block;
				width: inherit;
				float: none;
			}
			.image {
				display: block;
				width: 100%;
				margin-bottom: 0px;
				&:after {
					padding-bottom: 70.69%;
				}
			}
		}

	}
}
