.content-section {
    padding-top: $line-height-computed;
    padding-bottom: $line-height-computed;
    &.double-padding {
        padding-top: $line-height-computed * 2;
        padding-bottom: $line-height-computed * 2;
    }
    &.no-top-pad {
        padding-top: 0;
    }
    &.no-bottom-pad {
        padding-bottom: 0;
    }
    &:last-child.bg-trapezoid {
        &:after {
            display: none;
        }
    }
    &:first-child.bg-trapezoid {
        &:before {
            display: none;
        }
    }
    @include breakpoint-sm() {
        &.vertical-center {
            .row {
                display: flex;
                align-items: center;
            }
        }
    }
    .section-heading, .section-title {
        color: black;
        font-size: 24px;
        line-height: 25px;
        letter-spacing: 1px;
        padding: 0px 0 25px 0px;
        margin: 0;
        @include breakpoint-sm() {
            font-size: 27px;
            line-height: 30px;
            padding: 0px 0px 30px;
            max-width: 50%
        }
    }
}

.narrow-content .container-fluid {
    max-width: 870px;
}

.map-text {
    background-color: white;
    margin: 0;
    padding: 20px;
    @include breakpoint-max($screen-sm) {
        .wpb_col {
            margin: 10px 0!important;
            padding: 0;
        }
    }
    .wpb_text_column {
        padding: 0!important;
    }
}

.wpb_text_column {
    background: white;
}

.bg-trapezoid {
    @include angle(both, false, 1.1deg);
    margin-top: $line-height-computed;
    margin-bottom: $line-height-computed;
    padding: 40px 0;
    background: black;
    @include breakpoint-sm() {
        margin-top: $line-height-computed + 10px;
        margin-bottom: $line-height-computed + 10px;
        padding: 50px 0!important;
    }
    .section-heading, .section-title {
        color: white;
        font-size: 24px;
        line-height: 25px;
        letter-spacing: 1px;
        padding: 0px 0 10px 0px;
        @include breakpoint-sm() {
            font-size: 27px;
            line-height: 30px;
            padding: 0px 0 30px;
        }
    }
    .wpb_text_column {
        padding: 20px;
    }
    .col-sm-4, .col-md-4, .col-lg-4,
    .col-sm-6, .col-md-6, .col-lg-6 {
        .action-card .content {
            padding: 20px;
            @include breakpoint-md() {
                padding: 30px;
            }
        }
    }
    .wp-caption {
        background: black;
        .wp-caption-text {
            background: white;
            @include breakpoint-sm() {
                background: black;
                color: white;
            }
        }
    }
}
