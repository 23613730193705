@import "common/fonts";
@import "common/variables";
// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import "../../bower_components/font-awesome/scss/font-awesome.scss";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_mixins.scss";
@import "common/_mixins.scss";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_normalize.scss";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_print.scss";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_scaffolding.scss";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_tables.scss";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_forms.scss";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_buttons.scss";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_component-animations.scss";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_thumbnails.scss";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_alerts.scss";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_responsive-utilities.scss";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_modals.scss";
@import "../../bower_components/slick-carousel/slick/slick.scss";
@import "common/_global.scss";
@import "common/_animations.scss";
@import "vendor/_fullcalendar.scss";
@import "vendor/_gf-bootstrap.scss";
@import "elements/_buttons.scss";
@import "elements/_icons.scss";
@import "elements/_type.scss";
@import "components/_accordion.scss";
@import "components/_action-card.scss";
@import "components/_calendar.scss";
@import "components/_faq.scss";
@import "components/_filters.scss";
@import "components/_forms.scss";
@import "components/_header-search.scss";
@import "components/_lazy-load.scss";
@import "components/_media-slider.scss";
@import "components/_page-header.scss";
@import "components/_primary-nav.scss";
@import "components/_search.scss";
@import "components/_simple-callout.scss";
@import "components/_staff-card.scss";
@import "components/_tabs.scss";
@import "components/_vc_gmaps.scss";
@import "components/_vc_text-block.scss";
@import "components/_wp-classes.scss";
@import "components/home-slider/_slide.scss";
@import "components/home-slider/_slider.scss";
@import "modules/_footer.scss";
@import "modules/_header.scss";
@import "modules/_page-intro.scss";
@import "modules/_staff-list.scss";
@import "modules/_vc_row.scss";
@import "modules/single-event/_entry-meta.scss";
@import "modules/single-event/_performances.scss";
@import "partials/archive.scss";
@import "partials/single-article.scss";
@import "partials/single-event.scss";
@import "partials/single-image.scss";
@import "partials/single-video.scss";
@import "common/_utilities.scss";
// endbower
@import "common/global";
@import "common/utilities";

main {
    padding-top: 57px;
    padding-bottom: 10px;
    @include breakpoint-sm() {
		padding-top: 100px;
		padding-bottom: 50px;
    }

    &.no-page-header {
	    padding-top: 70px;
        @include breakpoint-sm() {
			padding-top: 150px;
		}
    }
}


@media (max-width: 753px) {
  body {
    background-color: #ffffff !important;
  }
}
