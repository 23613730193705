figure.video {
	&.wp-caption {
		border: none;
		padding: 0;
		.wp-caption-text {
			font-size: 15px;
			@include breakpoint-sm() {
				font-size: 17px;
			}
		}
	}
	.video {
		position: relative;

		&:before {
			content: ' ';
			display: block;
			padding-bottom: 291 / 509 * 100%;
		}
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 3;
		}
		.poster {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 4;
			// pointer-events: none;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
			img {
				display: none;
			}
			&:after {
				content: '\f04b';
				display: block;
				position: absolute;
				top: 50%;
				left: 50%;
				width: 70px;
				height: 70px;
				opacity: 1.0;
				color: $color-white;
				background-color: $color-black;
				font-family: 'FontAwesome';
				font-size: 30px;
				text-align: center;
				line-height: 70px;
				border-radius: 35px;
				cursor: pointer;
				transform: translate3d(-50%, -50%, 0);
				transition: all 333ms ease-in-out;
			}
			&:hover {
				&:after {
					color: $color-black;
					background-color: $color-white;
				}
			}
		}

	}
}

.col-sm-12, .col-md-12, .col-lg-12 {
	figure.video {
		@include breakpoint-sm() {
			padding: 0 10%;
		}
	}
}
