// WordPress Generated Classes
// http://codex.wordpress.org/CSS#WordPress_Generated_Classes

// Media alignment
.alignnone {
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
    height: auto;
}

.aligncenter {
    display: block;
    margin: ($line-height-computed / 2) auto;
    height: auto;
}

.alignleft,
.alignright {
    margin-bottom: ($line-height-computed / 2);
    height: auto;
}

@media (min-width: $screen-sm-min) {
    // Only float if not on an extra small device
    .alignleft {
        float: left;
        margin-right: ($line-height-computed / 2);
    }
    .alignright {
        float: right;
        margin-left: ($line-height-computed / 2);
    }
}


// Text meant only for screen readers
.screen-reader-text {
    @extend .sr-only;
    @extend .sr-only-focusable;
}

.text-transform-initial{
    text-transform: initial;
}

.text-xs-left { text-align: left; }
.text-xs-right { text-align: right; }
.text-xs-center { text-align: center; }
.text-xs-justify { text-align: justify; }

@media (min-width: $screen-sm-min) {
    .text-sm-left { text-align: left; }
    .text-sm-right { text-align: right; }
    .text-sm-center { text-align: center; }
    .text-sm-justify { text-align: justify; }
}

@media (min-width: $screen-md-min) {
    .text-md-left { text-align: left; }
    .text-md-right { text-align: right; }
    .text-md-center { text-align: center; }
    .text-md-justify { text-align: justify; }
}

@media (min-width: $screen-lg-min) {
   .text-lg-left { text-align: left; }
   .text-lg-right { text-align: right; }
   .text-lg-center { text-align: center; }
   .text-lg-justify { text-align: justify; }
}