a.link-button {
	padding: 14px 18px;
	@include breakpoint-sm() {
		padding: 12px 18px;
	}
	font-size: 16px;
    font-weight: bold;
    display: inline-block;
    text-align: center;
	&.white {
		border: 1px solid $color-black;
		background-color: $color-white;
		color: $color-black;
		margin-right: 10px;
		margin-bottom: 10px;
	}
	&.black {
		border: 1px solid $color-white;
		background-color: $color-black;
		color: $color-white;
	}
}

.nav-links {
	@extend .clearfix;
	// padding: 40px 0 30px;
	// @include breakpoint-sm() {
	// 	padding: 50px 0 0;
	// }

	.nav-previous { float: right; }
	.nav-next { float: left; }

	.nav-previous, .nav-next {
		a { 
			@extend a.link-button, a.link-button.black;
			svg {
				vertical-align: -2px;
			}
			&.link-button {
				padding: 14px 20px;
				@include breakpoint-sm() {
					padding: 14px 30px;
				}
			}
		}
	}
	.nav-previous {
		a {
			svg {
				margin-left: 15px;
			}
		}
	}
	.nav-next {
		a {
			svg {
				margin-right: 15px;
			}
		}
	}
}

.search-results .nav-links {
	padding: 40px 0 30px;
	@include breakpoint-sm() {
		padding: 50px 0 0;
	}
}
.post-type-archive .nav-links {
	padding: 20px 0 30px;
	@include breakpoint-sm() {
		padding: 30px 0 0;
	}
}