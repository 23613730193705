.nav-primary {
    display: block;
    position: fixed;
    top: 0;
    left: 100%;
    right: 0;
    bottom: 0;
    width: 100%;
    min-height: 100vh;
    background-color: $gray-base;
    z-index: $zindex-navbar-fixed;
    transition: transform 625ms 0s cubic-bezier(.215, .61, .355, 1), -webkit-transform 625ms 0s cubic-bezier(.215, .61, .355, 1);
    text-align: left;
    padding: 60px 0 0;


    ul.nav {
        list-style: none;
        margin: 0px;
        padding: 0px;
        > li {
            position: relative;
            padding: 0px 0px;
            margin: 5px 0px;
            animation-duration: 300ms;
            animation-fill-mode: both;
            @for $i from 1 through 20 {
                &:nth-child(#{$i}) {
                    animation-delay: $i * 100ms + 200ms;
                }
            }
            a {
                display: block;
                padding: 16px;
                background: #00b1a3;
                color: $color-black;
                font-size: 16px;
                font-family: 'Druk';
                font-weight: 400;
                text-transform: uppercase;
            }
            &.menu-item-has-children {
                .toggle {
                    position: absolute;
                    top: 16px;
                    left: auto;
                    right: 16px;
                    border: none;
                    outline: none;
                    background: transparent;
                    width: 18px;
                    height: 23px;
                    padding: 0px 0;
                    transform: rotateZ(180deg);
                    transition: transform 333ms ease-in-out;
                    svg {
                        content: url(../images/svg-sprite/nav-arrow-up.svg);
                        display: block;
                        width: 100%;
                        height: 100%;
                        color: $color-black;
                    }
                }
            }
            &.active {
                .toggle {
                    transform: rotateZ(0deg);
                }
                a {
                    margin-bottom: 5px;
                }
            }
            .sub-menu {
                display: none;
                list-style: none;
                margin: 0px;
                padding: 0px;
                overflow: hidden;
                > li {
                    padding: 0px;
                    margin: 5px 0px;
                    a {
                        background-color: $color-peach;
                        color: $color-black;
                    }
                }
            }
        }
    }
    ul.social-icons {
        display: block;
        padding: 20px 0;
        text-align: center;
    }
}

@include breakpoint-sm() {
    .nav-primary {
        padding: 130px 0px 0px 130px;
        ul.nav {
            list-style: none;
            margin: 0px;
            padding: 0px;
            > li {
                position: relative;
                padding: 5px 0 5px 40px;
                margin: 0px;
                background: transparent;
                transition: all 333ms ease-in-out;
                a {
                    padding: 0;
                    color: $color-teal;
                    font-size: 27px;
                    font-family: 'Druk';
                    font-weight: 500;
                    text-transform: uppercase;
                    background: transparent;
                    letter-spacing: 1px;
                }
                &.menu-item-has-children {
                    .toggle {
                        position: absolute;
                        top: 12px;
                        left: 0px;
                        right: auto;
                        border: none;
                        outline: none;
                        background: transparent;
                        width: 30px;
                        height: 23px;
                        padding: 0px 0;
                        transform: rotateZ(180deg);
                        transition: all 333ms ease-in-out;
                        svg {
                            content: url(../images/svg-sprite/nav-arrow-up.svg);
                            display: block;
                            width: 100%;
                            height: 100%;
                            color: $color-teal;
                        }
                    }
                }
                &.active {
                    .toggle {
                        transform: rotateZ(0deg);
                    }
                }
                .sub-menu {
                    list-style: none;
                    margin: 0px;
                    padding: 0px;
                    overflow: hidden;
                    transition: transform 333ms ease-in-out;
                    > li {
                        padding: 4px 0px 4px 34px;
                        a {
                            background-color: transparent;
                            color: $color-peach;
                        }
                    }
                }
            }
        }
        ul.social-icons {
            display: none;
        }
    }
}

body.primary-nav-active {
    overflow: hidden;
    nav.nav-primary {
        transform: translate3d(-100%, 0, 0);
        -webkit-overflow-scrolling: touch;
        overflow-y: auto;
        ul.nav {
            > li {
                animation-name: bounce;
            }
        }
    }
}

html[data-useragent*="Trident"][data-platform="Win32"] {
    body.primary-nav-active {
        nav.nav-primary {
            transform: translate3d(0, 0, 0);
            left: 0%;
        }
    }
}
