footer {
    // position: fixed;
    // left: 0;
    // right: 0;
    // bottom: 0;
    padding: 20px 0 25px;
    text-align: center;
    background: black;
    color: white;
    font-size: 0px; // hack, will fix
    @include breakpoint-md() {
        height: 90px;
        padding: 0px;
    }
    .footer-logo {
        display: none;
        @include breakpoint-lg() {
            display: inline-block;
            vertical-align: middle;
            margin: 0px 70px 0px 0px;
            svg {
                width: 165px;
                height: 90px;
            }
        }
    }
    .footer-nyu-logo {
        display: block;
        margin: 0px;
        svg {
            width: 87px;
            height: 31px;
        }
        @include breakpoint-md() {
	        display: inline-block;
	        vertical-align: middle;
            margin: 0px 0px 0px 45px;
            svg {
                width: 71px;
                height: 89px;
            }
        }
    }
}

.social-icons {
    display: block;
    vertical-align: middle;
    list-style: none;
    margin: 0;
    padding: 0 0 5px 0px;
    @include breakpoint-sm() {
	    padding: 0 0 15px;
    }
    @include breakpoint-md() {
        display: inline-block;
	    padding: 0;
        &:after {
            content: '|';
            padding-left: 4px;
            font-size: 15px;
            display: inline-block;
            color: white;
            vertical-align: 5px;
            font-weight: 700;
        }
    }
    li {
        display: inline-block;
        padding: 0px;
        .fa-twitter {
            padding: 0 28px 0 35px;
        }
        a {
            color: white;
            font-family: 'Druk';
            font-weight: 400;
            font-size: 36px;
            text-transform: uppercase;
        }
        @include breakpoint-md() {
	        padding: 0 20px 0 0;
            .fa-twitter {
                padding: 0px;
            }
        	a {
	            font-size: 22px;
        	}
        }
    }
}

nav.nav-footer {
    display: inline-block;
    vertical-align: middle;
    padding: 0px 0px 20px;
    @include breakpoint-md() {
        padding: 0px;
    }
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            position: relative;
            display: block;
            padding: 6px 25px;
            a {
                color: white;
                font-family: 'Druk';
                font-weight: 400;
                font-size: 10pt;
                text-transform: uppercase;
            }
            @include breakpoint-sm() {
                display: inline-block;
                padding: 0px 25px 0 23px;
                a {
                    font-size: 9pt;
                }
                &:after {
                    content: '|';
                    font-size: 15px;
                    display: block;
                    position: absolute;
                    top: -2px;
                    right: 0;
                    color: white;
                    font-weight: 700;
                }
                &:last-child {
                    &:after {
                        display: none;
                    }
                }
            }
            &.menu-join-our-newsletter {
                a:before {
                    content: url(../images/svg-sprite/icon-newsletter.svg);
                    display: none;
                    margin: 0px 12px 0px 0px;
                    vertical-align: middle;
                    @include breakpoint-sm() {
                        display: inline-block;
                    }
                }
            }
        }
    }
}
