.tabs{
  position: relative;
  text-align: center;

  @include breakpoint-sm() {
    text-align: right;
    margin-top: -130px;
  }
  @include breakpoint-md() {
    margin-top: -150px;
  }
}
.events-tab{
  background-color: $color-pink;

  @include breakpoint-md() {
    background-color: transparent;
  }
}
.tab{
  background-color: $color-yellow;
  color: #000;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  height: 50px;
  padding: 15px 5px;
  text-align: center;
  text-transform: uppercase;
  transition: clip-path 0.175s ease-out;
  width: 49%;
  width: calc(50% - 2px);
  
  @include breakpoint-sm() {
    clip-path: polygon(0% 15px, 15px 0%, 100% 0%, 100% 100%, 0% 100%);
    width: 165px;
  }
  &.active{
    background-color: #fff;
  }
  &:hover{
    clip-path: polygon(0% 0%, 0% 0%, 100% 0%, 100% 100%, 0% 100%);
    transition: clip-path 0.25s ease-in;
  }
}
.post-type-archive-event #posts-list {
  @include breakpoint-max($screen-xs-max) {
    display: block!important;
    padding-top: 50px;
    padding-bottom: 10px;
  }
}