#event-calendar {
  @include angle('after', true, 1.1deg);
  position: relative;
  z-index: 2;
  @include breakpoint-sm() {
    &:after {
      content: normal;
      display: none;
    }
  }
}
.fc-unthemed{
  background-color: $color-pink;
  margin-bottom: 40px;
  padding-bottom: 45px;
  @include breakpoint-sm{
    background-color: #fff;
  }
}
.fc-body{
  overflow: visible;
}
.fc-view{
  table{
    width: 100%;
  }
  .fc-content-skeleton{
    .fc-day-top{
      @include breakpoint-sm{
        padding: 10px;
      }
    }
    td{
      min-height: 7%;
      position: relative;      
      &:after{
        border-left: 1px solid #000;
        content: '';
        display: inline-block;
        @include breakpoint-sm{
          display: none;
        }
      }
      @include breakpoint-sm{
        padding: 0 10px;
        position: relative;
        width: 14.285%;
      }
    }
    tbody{
      tr:not(:first-child){
        display: none;
        @include breakpoint-sm{
          display: table-row;
          .fc-content{
            border-top: 2px solid #000;
          }
        }
      }
      tr{
        .fc-content{
          @include breakpoint-sm{
            padding: 10px 0;
          }
        }
      }
      @include breakpoint-max($screen-xs-max){
        height: 0;
        td{
          position: relative;
        }
      }
    }
  }
  .fc-day-grid-container{
    border: 1px solid #000;
    border-radius: 3px;
    height: auto!important;
    position: relative;
    @include breakpoint-sm{
      border: 3px solid #000;
    }
  }
  .fc-week{
    border-bottom: 1px solid #000;
      min-height: 14%;
      position: relative; 
    @include breakpoint-sm{
      min-height: 150px;
      border-bottom: 3px solid #000;
    }
  }
  .fc-week:last-child{
    border-bottom: none;
  }
  .fc-day-header{
    font-size: 12px;
    font-weight: 300;
    text-align: center;
    text-transform: uppercase;

    @include breakpoint-sm{
      font-size: 25px;
      font-weight: 700;
      padding: 25px 0;
    }
  }
}
.fc-day-number{
  @include breakpoint-max($screen-xs-max){
      display: block;
      left: 0;
      line-height: 1;
      position: absolute;
      right: 0;
      top: calc(100% - 7.5px);
      width: 100%;
  }
}
.fc-header-toolbar{
  color: #000;
  position: relative;
  text-align: center;
  @include breakpoint-sm{
    background-color: #000;
    color: #fff;
    padding: 24px 18px;
  }
  h2{
    padding: 0;
    @include breakpoint-max($screen-xs-max){
        font-size: 14px;
    }
  }
  .fc-button{
    border-style: none;
      border-color: transparent;
      background: transparent;
      color: inherit;
      font-weight: bold;
      letter-spacing: 1px;
      outline: none;
      padding: 0 18px;
    position: absolute;
    text-transform: uppercase;
    top: 50%;
    transform: translate(0, -50%);
    @include breakpoint-sm{
      font-size: 22px;
    }
  }
  .fc-button{
    &:before, &:after{
      font-size: 0;
      @include breakpoint-sm{    
        font-size: 22px;
        display: inline-block;
        margin-top: -7px;
        padding: 0 20px;
        vertical-align: middle;
      }
    }
  }
  .fc-prev-button{
    left: 0;
    &:after{
      content: 'Back';
    }
  }
  .fc-next-button{
    right: 0;
    &:before{
      content: 'Next';
    }
  }
}
.fc-content{
  background-color: #000;
    clip-path: polygon(0 0, 100% 0, 100% 100%);
  color: #000;
  font-size: 0px;
    height: 15px;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: -23px;
    width: 15px;

  @include breakpoint-sm{
    background-color: transparent;
      clip-path: none;
    font-size: 16px;
    font-weight: bold;
      height: auto;
    overflow: initial;
      pointer-events: initial;
    position: relative;
      right: auto;
      top: auto;
      width: auto;
  }
}
.fc-view-container *, .fc-view-container :after, .fc-view-container :before {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}
.fc-row:first-child table {
    border-top: 0 hidden transparent;
}
.fc-row table {
    border-left: 0 hidden transparent;
    border-right: 0 hidden transparent;
    border-bottom: 0 hidden transparent;
}
.fc table {
    width: 100%;
    box-sizing: border-box;
    table-layout: fixed;
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 1em;
}
.fc-bg table, .fc-row .fc-bgevent-skeleton table, .fc-row .fc-highlight-skeleton table {
    height: 100%;
}
.fc-today-button{
  display: none;
}
.fc-day-top{
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.8px;
  text-align: center;
    &.fc-state-highlight{
      background-color: transparent;
    }
  @include breakpoint-sm{
    text-align: right;
  }
}
.fc-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  .fc-day{
    border-right: 1px solid #000;
    @include breakpoint-sm{
      border-right: 3px solid #000;
    }
  }
}
.fc-state-highlight{
  background-color: transparent;
  // color: #fff;
}