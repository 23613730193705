.home-hero-slider.slick-slider {
	.slick-slide {
		@extend .clearfix;
		outline: none;
		.image {
			display: block;
			position: relative;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
			max-height: 400px;
			overflow: hidden;
			z-index: 10;
			img {
				width: 0;
				height: 0;
			}
			&:after {
				content: ' ';
				display: block;
				padding-bottom: 460/640 * 100%;
			}
			@include breakpoint-sm() {
				&:after {
					padding-bottom: 53%;
				}
			}
			@include breakpoint-md() {
				&:after {
					padding-bottom: 430/1260 * 100%;
				}
			}
		}
		.content {
			z-index: 15;
			padding-top: 40px;
			.top {
				padding-bottom: 10px;
			}
		}
		.pretitle {
			font-family: 'Druk';
			font-weight: 400;
			font-size: 15px;
		}
		.title {
			font-family: 'Druk';
			font-weight: 700;
			font-size: 50px;
			line-height: 50px;
		}
		.link-button {
			margin: 10px 0px 0px;
			float: right;
			padding: 14px 24px;
    		min-width: 100%;
    		margin-bottom: 30px!important;
		}
		@include breakpoint-xs() {
			.link-button {
				float: left;
				min-width: 50%;
			}
		}
		@include breakpoint-sm() {
			.content {
				padding-bottom: 50px;
			}
			.image {
				max-height: 450px;
			}
			.title {
				font-size: 58px;
				line-height: 58px;
			}
			.link-button {
				float: left;
				min-width: 30%;
				margin-bottom: 40px!important;
			}
		}
		@include breakpoint-md() {
			.pretitle {
				font-family: 'Druk';
				font-weight: 400;
				font-size: 17px;
			}
			.title {
				float: left;
				width: 70%;
				font-family: 'Druk';
				font-weight: 700;
				font-size: 65px;
				line-height: 60px;
			}
			.link-button {
				margin: 0px 15px;
				float: right;
				padding: 14px 20px;
	    		min-width: 24%;
	    		margin-bottom: 0px!important;
			}
		}
	}
}