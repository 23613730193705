.svg-inline {
    display: inline-flex;
    align-self: center;
    position: relative;
    width: 1em;
    .icon-svg {
        bottom: -0.125em;
        position: absolute;
    }
}

.icon-svg {
    width: 1em;
    height: 1em;
    fill: currentColor;
    &-nyu-logo{
        width: 2.9em;
    }
}

.desktop-logo-bg {
    fill: none;
}
