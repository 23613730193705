.entry-meta {
	padding-bottom: 30px;
	z-index: 1;
    position: relative;
	.metas {
		padding-bottom: 10px;
		ul {
			list-style: none;
			margin: 0;
			padding: 0;
			li {
				display: inline-block;
				font-size: 15px;
				font-weight: 300;
				text-transform: uppercase;
				&:after {
					content: '|';
					display: inline-block;
					margin: 0 5px 0 10px;
				}
				&:last-child {
					&:after {
						content: normal;
					}
				}
                a {
                    color: inherit;
                }
			}
		}
	}
	.share-options {
		a {
			color: $color-black;
			font-size: 20px;
			font-weight: 300;
			margin-right: 15px;
		}
		span {
			color: $color-black;
			font-size: 15px;
			font-weight: 300;
			vertical-align: text-bottom;
		}
	}
	@include breakpoint-sm() {
		padding-bottom: 20px;
		.metas {
			ul {
				li {
					font-size: 17px;
					font-weight: 600;
					&:after {
						margin: 0 10px 0 15px;
					}
				}
			}
		}
		.share-options {
			a {
				font-weight: 600;
				margin-right: 20px;
			}
			span {
				font-size: 17px;
				font-weight: 600;
			}
		}
	}
	@include breakpoint-md() {
		.metas {
			padding-bottom: 0px;
		}
	}
}