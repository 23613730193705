header.banner {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
    background: black;
    color: white;
    text-align: center;

    height: 57px;
    box-shadow: 0px 0px 8px 5px rgba(0, 0, 0, 0.2);
    z-index: $zindex-navbar;

    &:after {
        content: ' ';
        display: block;
        position: absolute;
        top: 0;
        left: 100%;
        right: auto;
        height: 57px;
        z-index: 9999;
        width: calc(100% - 10px);
        background: black;
	    transition: transform 625ms 0s cubic-bezier(.215, .61, .355, 1), -webkit-transform 625ms 0s cubic-bezier(.215, .61, .355, 1);
    }

    .container-fluid {
    	height: 57px;
    	overflow: visible;
    }

    @include breakpoint-sm() {
	    height: 100px;
	    &:after {
	    	display: none;
	    }

	    .container-fluid {
	    	height: 100px;
	    	overflow: visible;
	    }
    }

    .calendar {
    	float: left;
    	display: block;
    	padding-top: 10px;
    	@include breakpoint-sm() {
    		display: none;
    	}
    	.calendar-button {
    		display: inline-block;
    		color: #fff;
    		width: 35px;
    		height: 35px;
    		padding: 0px;
    		border: none;
    		background: transparent;
    		outline: none;
    		color: white;
    		svg {
    			width: 100%;
    			height: 100%;
	    		fill: white;
    		}
    	}
    }

    .logo {
    	display: inline-block;
    	float: none;
    	margin: -4px auto 0px;
	    width:auto;
        filter: drop-shadow( 0px 0px 5px rgba(0, 0, 0, 0.2) );
    	.icon-svg-logo-mobile {
    		display: block;
		    width: 161px;
		    height: 74px;
		    color: white;
    	}
    	.icon-svg-logo-desktop {
    		display: none;
    		width: 189px;
    		height: auto;
    		color: white;
    	}

    	@include breakpoint-sm() {
	    	display: block;
    		float: left;
	    	margin: 11px 0px 0px 8px;
    		width: auto;

	    	.icon-svg-logo-mobile {
	    		display: none;
	    	}
	    	.icon-svg-logo-desktop {
	    		display: block;
	    	}
    	}
    }

    .controls {
    	@include clearfix;
    	float: right;
    	padding: 11px 0 0;

		@include breakpoint-sm() {
	    	padding: 22px 0 0;
		}

		.hamburger {
		    z-index: 10000;
		    position: relative;
			float: left;
			border: none;
			background: transparent;
			width: 35px;
			height: 35px;
		    padding: 0px 3px 0px 0px;
		    outline: none;

			@include breakpoint-sm() {
				width: 66px;
				height: 60px;
				margin: -5px 8px 0px 0px;
				padding: 14px;
			}

			.icon-svg-nav-burger {
				display: block;
				width: 100%;
				height: 100%;
			}

			.icon-svg-nav-close {
				display: none;
				width: 100%;
				height: 100%;
			}
		}
    }
}

nav.nav-banner {
	display: none;
	float: left;
	margin: 0px 26px 0px 0px;
	@include breakpoint-md() {
		display: block;
	}
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		li {
			line-height: 47px;
			display: inline-block;
			padding: 0pt 14px;
			a {
				font-family: 'Druk';
			    font-size: 22px;
			    font-weight: 700;
			    color: #fff;
			    text-transform: uppercase;
			    letter-spacing: 2px;
			}
		}
	}
}

#logo-desktop {
	.desktop-logo-bg {
		fill: black;
	}
}

body.primary-nav-active {
    header.banner {
    	&:after {
	        transform: translate3d(-100%, 0, 0);
    	}
    	.hamburger {
    		// padding: 2px 6px 3px 20px;

			.icon-svg-nav-burger {
				display: none;
			}
			.icon-svg-nav-close {
				display: block;
			}
    	}
    }
}
