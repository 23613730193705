header .controls .hsearch {
    position: relative;
    margin-top: 10px;
    width: 0%;
    min-width: 50px;
    height: 50px;
    float: left;
    display: none;
    overflow: hidden;
    transition: all 333ms ease-in-out;
    -webkit-backface-visibility: hidden;

	@include breakpoint-sm() {
		display: block;
	}

    .hsearch__input {
	    position: absolute;
	    top: 0;
	    right: 0;
	    border: none;
	    outline: none;
	    background: #fff;
	    width: 100%;
	    height: 50px;
	    margin: 0;
	    z-index: 10;
	    padding: 0px 55px 0px 20px;
	    font-family: inherit;
	    font-size: 20px;
	    color: #2c3e50;
	    opacity: 0;
	    -webkit-appearance: none;
	    -webkit-border-radius: 0px;
    	transition: all 333ms ease-in-out;
    	&::-ms-clear {
		    display: none;
		}
    }

    .icon, .hsearch__submit {
	    width: 50px;
	    height: 50px;
	    display: block;
	    position: absolute;
	    right: 0;
	    top: 0;
	    padding: 0;
	    margin: 0;
	    line-height: 50px;
	    text-align: center;
	    cursor: pointer;
	}
	.hsearch__submit {
        background: #fff;
	    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	    filter: alpha(opacity=0);
	    opacity: 0;
	    color: transparent;
	    border: none;
	    outline: none;
	    z-index: -1;
    }
    .icon {
	    color: #fff;
	    background: transparent;
	    z-index: 90;
	    font-size: 22px;
	    font-family: 'icomoon';
	    speak: none;
	    font-style: normal;
	    font-weight: normal;
	    font-variant: normal;
	    text-transform: none;
	    -webkit-font-smoothing: antialiased;
    }

	&.opened, .search-results & {
	    min-width: 400px;
		.hsearch__input {
			opacity: 1;
		}
		.hsearch__submit {
    		z-index: 90;
		}
		.icon {
			background: transparent;
		    color: black;
		    z-index: 11;
		}
	}

    .search-results & {
    	@include breakpoint-max($screen-sm-max) {
			top: 0;
		}
    	@include breakpoint-max($screen-xs-max) {
	        display: block;
		    left: 50%;
    		max-width: calc(100% - 30px);
    		min-width: initial;
		    padding: 0 15px;
	        position: absolute;
		    top: 85px;
		    transform: translate(-50%, 0);
		    transition: transform 0.075s ease-in-out;
		    width: 400px;
		    .hsearch__input{
		    	border: 2px solid #7f7f7f;
		    }
		    &.shrink{
		    	transform: rotate3d(1,0,0,90deg) translate(-50%, 0);
		    }
		}
	}
}

nav.nav-primary {
	.hsearch {
		position: relative;
		margin: 5px 0px;
		.hsearch__input {
			display: block;
			width: 100%;
            padding: 16px;
            background: $color-white;
            color: $color-black;
            font-size: 16px;
            font-family: 'Druk';
            font-weight: 400;
            text-transform: uppercase;
            border: none;
            outline: none;
	    	&::-ms-clear {
			    display: none;
			}
		}
		.hsearch__submit {
			position: absolute;
			top: 7px;
			right: 16px;
			padding: 0px;
			border: none;
			background: transparent;
			color: black;
			line-height: 40px;
			font-size: 20px;
			font-family: 'FontAwesome';
			outline: none;
		}
		.icon {
			display: none;
		}
		@include breakpoint-sm() {
			display: none;
		}
	}
}
