@mixin breakpoint-custom($breakpoint) {
    @media (min-width: #{$breakpoint}) {
        @content;
    }
}

@mixin breakpoint-max($breakpoint) {
    @media (max-width: #{$breakpoint}) {
        @content;
    }
}

@mixin breakpoint-xs {
    @include breakpoint-custom($screen-xs) {
        @content;
    }
}

@mixin breakpoint-sm {
    @include breakpoint-custom($screen-sm) {
        @content;
    }
}

@mixin breakpoint-md {
    @include breakpoint-custom($screen-md) {
        @content;
    }
}

@mixin breakpoint-lg {
    @include breakpoint-custom($screen-lg) {
        @content;
    }
}

@mixin list-unstyled {
    padding-left: 0;
    list-style: none;
}

@mixin list-border {
    border-bottom: 2px solid #c7c7c7;
}

@mixin list-inline($padding:5px) {
    @include list-unstyled;
    margin-left: -($padding);

    > li {
        display: inline-block;
        padding-left: $padding;
        padding-right: $padding;
    }
}

@mixin theme($color) {
    .theme-#{$color} & {
        @content;
    }
}

@mixin angle($pseudo:both, $flip: false, $angle: 1.5deg) {
    // Possible values for $pseudo are: before, after, both
    @if $pseudo == 'before' or $pseudo == 'after' or $pseudo == 'both' {
        position: relative;
        z-index: 1;
        $selector: if($pseudo == 'both', '&:before,&:after', '&:#{$pseudo}');

        #{$selector} {
            background: inherit;
            content: '';
            display: block;
            height: 100%;
            left: 0;
            position: absolute;
            right: 0;
            z-index: -1;
            -webkit-backface-visibility: hidden; // for Chrome Windows
        }

        @if $pseudo == 'before' {
            #{$selector} {
                top: 0;

                @if $flip {
                    transform: skewY($angle * -1);
                    transform-origin: 0 0;
                } @else {
                    transform: skewY($angle);
                    transform-origin: 100% 0;
                }
            }
        }

        @if $pseudo == 'after' {
            #{$selector} {
                bottom: 0;

                @if $flip {
                    transform: skewY($angle);
                    transform-origin: 0 100%;
                } @else {
                    transform: skewY($angle * -1);
                    transform-origin: 100%;
                }
            }
        }

        @if $pseudo == 'both' {
            &:before {
                top: 0;

                @if $flip {
                    transform: skewY($angle * -1);
                    transform-origin: 0 0;
                } @else {
                    transform: skewY($angle);
                    transform-origin: 100% 0;
                }
            }

            &:after {
                bottom: 0;

                @if $flip {
                    transform: skewY($angle * -1);
                    transform-origin: 0 0;
                } @else {
                    transform: skewY($angle);
                    transform-origin: 0 100%;
                }
            }
        }
    }
}

@mixin intrinsic-ratio($width:16, $height:9, $selector: iframe) {
    position: relative;
    height: 0;
    overflow: hidden;
    padding-bottom: ($height * 100% / $width);

    #{$selector} {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

@mixin absolute-centered($pos: false) {
    position: absolute;
    @if($pos == vertical){
        top: 50%;
        transform: translate(0,-50%);
    }@else if($pos == horizontal){
        left: 50%;
        transform: translate(-50%,0);
    }@else{
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
    }
}
@mixin delays($min,$max,$steps){
    $e : ($max - $min) / $steps;
    @for $j from 1 through $e {
        $val : $steps * ($j - 1) + $min;
        .delay-#{$val} {
          animation-delay: #{$val / 1000}s; }
    }
}
@mixin durations($min,$max,$steps){
    $e : ($max - $min) / $steps;
    @for $j from 1 through $e {
        $val : $steps * ($j - 1) + $min;
        .duration-#{$val} {
          animation-delay: #{$val / 1000}s; }
    }
}
@mixin extendAnimation($arr,$prop,$part){
    $val : map-get($arr, $prop);
    $start : map-get(nth($val, 1), vals);
    $end : map-get(nth($val, length($val)), vals);
    @if $part == start{
        @each $p, $v in $start {
            #{$p}: $v;
        }
    }@else if $part == end{
        @each $p, $v in $end {
            #{$p}: $v;
        }
    }@else{
        animation-name: #{$prop};
    }
}
@mixin animationSuite($arr){
    @each $prop, $val in $arr {
        $start : map-get(nth($val, 1), vals);
        $end : map-get(nth($val, length($val)), vals);
        @keyframes #{$prop} {
            @for $i from 1 through length($val) {
                $per : ( ($i - 1) / (length($val) - 1) ) * 100;
                @if map-get(nth($val, $i), per) {
                    $per : map-get(nth($val, $i), per);
                } @else {}
                #{ $per }#{'%'} {
                    $item: map-get(nth($val, $i), vals);
                    @each $p, $v in $item {
                        #{$p}: $v;
                    }
                }
            }
        }
        .#{$prop}Start{
            @each $p, $v in $start {
                #{$p}: $v;
            }
        }
        .#{$prop}End{
            @each $p, $v in $end {
                #{$p}: $v;
            }
        }
        .#{$prop} {
            animation-name: #{$prop};
        }
    }
}