.page-intro-section {
	@include breakpoint-max($screen-xs-max) {
	    background-color: $color-teal;
	    @include theme(blue) {
	        background-color: $color-blue;
	    }
	    @include theme(yellow) {
	        background-color: $color-yellow;
	    }
	    @include theme(teal) {
	        background-color: $color-teal;
	    }
	    &.has-banner {
	        background-color: $color-teal;
	        @include theme(blue) {
	            background-color: $color-blue;
	        }
	        @include theme(yellow) {
	            background-color: $color-yellow;
	        }
	        @include theme(teal) {
	            background-color: $color-teal;
	        }
	    }
	    
	    @include angle('after', true, 1.1deg);

	    &.content-section {
			padding-top: 0px;
	    }
	}
}