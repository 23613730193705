figure.image {
	@include breakpoint-max ($screen-sm) {
		padding-bottom: 30px;
	}
	&.wp-caption {
		border: none;
		padding: 0;
		.wp-caption-text {
			font-size: 15px;
			@include breakpoint-sm() {
				font-size: 17px;
			}
		}
	}
	.image {
		position: relative;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center center;
		&:before {
			content: ' ';
			display: block;
			padding-bottom: 291 / 509 * 100%;
		}
		img {
			display: none;
		}
	}
}

.col-sm-12, .col-md-12, .col-lg-12 {
	figure.image {
		@include breakpoint-sm() {
			padding: 0 10%;
		}
	}
}