.home-hero-slider.slick-slider {
	display: none;
	.slick-prev,
	.slick-next {
		border: none;
		outline: none;
		background: transparent;
		opacity: 0;
		transition: all 333ms ease-in-out;
		font-size: 0;
	}
	.slick-prev {
		width: 30px;
    	height: 30px;
    	margin-right: 10px;
    	padding: 0;
		svg {
			transform: rotateZ(-90deg);
			fill: black;
    		width: 100%;
    		height: 100%;
		}
	}
	.slick-next {
		width: 30px;
    	height: 30px;
    	margin-left: 10px;
    	padding: 0;
		svg {
			transform: rotateZ(90deg);
			fill: black;
    		width: 100%;
    		height: 100%;
		}
	}
	.slick-controls {
		position: absolute;
		left: 0px;
		right: 0px;
		z-index: 20;
		transform: translate3d(0, -54%, 0);
		.buttons {
			background-color: white;
			display: inline-block;
			padding: 10px 15px 0px;
		}
	}
	&.slick-initialized {
		display: block;
		.slick-prev,
		.slick-next {
			opacity: 1;
		}
	}
}

body.home article section.bg-trapezoid:before {
	display: block !important;
}