body.single-news, body.single-article {
	background: black;
	@include breakpoint-sm() {
		background: white;
	}
	.page-header {
		.background[style*="background-image"] {
			@include breakpoint-max($screen-xs-max) {
	            background-size: cover;
	            min-height: 300px;
	            max-height: 350px;
	            &:before {
	                content: ' ';
	                display: block;
	                padding-bottom: 460/640 * 100%;
	            }
			}
		}
	}
	article {
		padding-top: 40px;
		@include breakpoint-sm() {
			padding-top: 0px;
		}
		&.has-featured-image {
			@include breakpoint-sm() {
				margin-top: -100px;
			}
		}
		.featured-image {
			display: none;
			position: relative;
			z-index: 90;
			@include breakpoint-sm() {
				display: block;
				padding-bottom: 40px;
			}
		}
		.content-section {
			padding: 0;
			@include breakpoint-sm() {
				padding: 20px 0;
			}
			.container {
				.entry-meta {
					background: white;
					padding-top: 20px;
					padding-bottom: 10px;
					@include breakpoint-sm() {
						padding-top: 0px;
						padding-bottom: 0px;
					}
				}
				.wpb_text_column {
					padding-top: 20px;
					padding-bottom: 20px;
					@include breakpoint-sm() {
						padding-top: 10px;
						padding-bottom: 10px;
					}
				}
				.entry-meta, .wpb_text_column {
					padding-left: 15px;
					padding-right: 15px;
					@include breakpoint-sm() {
						padding-left: 50px;
						padding-right: 50px;
					}
					@include breakpoint-md() {
						padding-left: 100px;
						padding-right: 100px;
					}
				}
			}
		}
	}
}

body.single-news {
	.page-header {
		.background {
			@include breakpoint-max($screen-xs-max) {
				background-image: url('../images/page-header-news.jpg');
			}
			@include breakpoint-sm() {
				background-image: url('../images/page-header-news.jpg') !important;
			}
		}
	}
	.entry-meta {
		h1 {
			font-size: 42px;
			line-height: 1;
			font-weight: 700;
			margin: 0;
		}
		time {
			font-size: 14px;
			font-weight: 500;
		}
		@include breakpoint-sm() {
			h1 {
				font-size: 68px;
				line-height: 1.1;
			}
			time {
				font-size: 15px;
			}
		}
	}
}

body.single-article {
	.page-header {
		.background {
			@include breakpoint-max($screen-xs-max) {
				background-image: url('../images/page-header-indefinite-articles.jpg');
			}
			@include breakpoint-sm() {
				background-image: url('../images/page-header-indefinite-articles.jpg') !important;
			}
		}
	}
	.entry-meta {
		h1 {
			font-size: 42px;
			line-height: 1;
			font-weight: 700;
			margin: 0 0 15px;
		}
		h4 {
			font-size: 14px;
			font-weight: 500;
			margin: 0;
		}
		p {
			font-size: 16px;
			padding-bottom: 0;
		}
		@include breakpoint-sm() {
			h1 {
				font-size: 68px;
				line-height: 1.1;
				margin: 0 0 20px;
			}
			h4 {
				font-size: 20px;
				margin: 0 0 5px;
			}
			p {
				font-size: 20px;
			}
		}
	}
}