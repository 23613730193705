.staff-card {
    @include make-sm-column(3);
    .staff-content {
        background-color: white;
        padding: 15px;
    }
    @include breakpoint-sm() {
        padding-bottom: 25px;
        .staff-content {
            padding: 0px;
        }
    }
    .col-sm-6 & {
        @include make-sm-column(6);
    }
    &__photo {
        margin-bottom: 13px;
        width: 100%;
    }
    &__name {
        font-size: 14px;
        margin: 0;
        @include breakpoint-sm() {
            font-size: 13px;
        }
    }
    &__role {
        margin: 0 0 3px;
        font-size: 17px;
        @include breakpoint-sm() {
            font-size: 15px;
        }
    }
    &__email, &__phone {
        font-size: 13px;
        margin: 0 0 2px;
        .fa {
            margin-right: 3px;
        }
        a {
            color: black;
            &:hover{
                color:$link-color;
            }
        }
    }
    &__phone {
        .fa {
            font-size: 1.3em;
            top: 2px;
            position: relative;
        }
    }
}
