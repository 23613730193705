@import "common/_animationVals.scss";
@include animationSuite($animations);
@include delays(50,1500,50);
@include durations(50,5000,25);
.anim {
  animation-duration: $animate-default-duration;
  -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  animation-timing-function: $animate-default-timing; 
  &.infinite {
    animation-iteration-count: infinite;
  }
  &.hinge {
    animation-duration: $animate-hinge-duration;
  }
}