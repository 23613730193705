.media-slider.slick-slider {
	min-height: 33vw;
	@include breakpoint-sm() {
		min-height: 0px;
	}

	.slick-prev, .slick-next {
		z-index: 5;
		position: absolute;
		top: 50%;
		transform: translate3d(0, -50%, 0);
		color: #fff;
		border: none;
		outline: none;
		background-color: #000;
		font-size: 0;
		width: 37px;
		height: 42px;
		svg {
			display: block;
			width: 100%;
			height: 100%;
			color: #fff;
		}
	}
	.slick-prev {
		left: -10px;
		right: auto;
		svg {
			transform: rotateZ(-90deg);
		}
	}
	.slick-next {
		left: auto;
		right: -10px;
		svg {
			transform: rotateZ(90deg);
		}
	}
	.slick-slide {
	    display: block;
		border: none;
		outline: none;
    	margin: 0px 7.5px;
		padding: 0px;
	    line-height: normal;
    	transition: none;

		.wp-caption-text {
			font-size: 15px;
			@include breakpoint-sm() {
				font-size: 17px;
			}
		}
	}
	.image.slick-slide {
		.image {
			background-color: black;
			cursor: pointer;
		}
	}
	.video.slick-slide {
		.video {

		}
	}
}

.bg-trapezoid {
	.media-slider.slick-slider {
		.slick-prev, .slick-next {
			color: #000;
			background-color: #fff;
			svg {
				color: #000;
			}
		}
	}	
}

.col-sm-12, .col-md-12, .col-lg-12 {
	.media-slider-wrapper {
		@include breakpoint-sm() {
			padding: 0 10%;
		}
	}
}

@include breakpoint-max($screen-xs-max) {
	.accordion-content {
		*[class^="col-"] {
			.media-slider-wrapper {
				padding-top: 15px;
			}
			&:nth-of-type(1) {
				.media-slider-wrapper {
					padding-top: 0px;
				}
			}	
		}
	}
}

.media-slider-wrapper .modal {
    text-align: center;
    padding: 0!important;
    &.modal-centered {
        &:before {
            content: '';
            display: inline-block;
            height: 100%;
            vertical-align: middle;
            margin-right: -4px;
        }
    }
    .modal-dialog {
        display: inline-block;
        text-align: left;
        vertical-align: middle;
        width: 100%;
        margin: 0;
    }
    .modal-content {
    	margin: -30px 0px !important;
	    display: block;
	    background: transparent;
	    .close {
	    	width: 35px;
	    	height: 35px;
	    	padding: 0px;
	    	margin: 10px 10px 0px 0px !important;
	    	background-color: transparent;
	    	border: none;
    	    position: absolute;
		    z-index: 2;
		    top: 15px;
		    right: 30px;
		    svg {
		    	width: 100%;
		    	height: 100%;
		    }
	    }
	    figcaption {
	    	color: white;
			font-size: 15px;
			@include breakpoint-sm() {
				font-size: 17px;
			}
	    }
    }
}
