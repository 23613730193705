.simple-callout {
	background-color: black;
	padding: 25px 20px;
	margin-top: 20px;
	h3 {
		color: white;
		font-size: 16px;
		font-weight: 300;
		letter-spacing: 1px;
		margin-bottom: 20px;
		line-height: 19px;
	}
	p {
		color: white;
		font-size: 15px;
	}
	a {
		font-size: 15px;
		color: white;
		font-weight: 600;
		text-decoration: underline;
	}
	.simple-callout__link {
		border: 1px solid $color-white;
		background-color: $color-black;
		font-size: 16px;
	    font-weight: bold;
	    display: inline-block;
	    text-align: center;
		padding: 14px 32px;
		margin-top: 10px;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}

	}
	@include breakpoint-xs() {
		padding: 30px;
		h3 {
			font-size: 18px;
			line-height: 21px;
		}
		p {
			font-size: 17px;
		}
		a {
			font-size: 17px;
		}
	}
}