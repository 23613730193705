@keyframes rotateCenter{
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg);}
}
.lazy-load{
	&:before{
	    animation: 1.5s rotateCenter ease-in-out infinite;
  		background-image: url('../../dist/images/svg-sprite/loading.svg');
		background-size: 50px;
  		content: '';
		display: block;
		height: 50px;
		left: 50%;
		margin-left: -25px;
    	margin-top: -25px;
    	position: absolute;
    	top: 50%;
		transform-origin: center center;
		width: 50px;
	}
	&.lazy-loaded{
		&:before{
			display: none;
		}
	}
}